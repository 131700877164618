import { Map } from 'immutable'
import {
  APP,
  CaseManager,
  Market,
  Name,
  NextTargetedVisit,
  Physician,
  ReferringEntity,
} from '~/apps/patientRecord/data/patientSummaryRecords'
import { Location } from '~/features/locationSearch'

export const getValuesFromPatientInfo = patientInfo =>
  Map({
    assignedApp: APP({
      id: patientInfo.app.id,
      name: patientInfo.app.name,
    }),
    caseManager: CaseManager({
      id: patientInfo.caseManager.id,
      name: patientInfo.caseManager.name,
    }),
    assignedMarket: Market({
      id: patientInfo.market.id,
      name: patientInfo.market.name,
      neighborhood: patientInfo.market.neightborhood,
      region: patientInfo.market.region,
    }),
    name: Name({
      firstName: patientInfo.demographics.firstName,
      lastName: patientInfo.demographics.lastName,
      middleName: patientInfo.demographics.middleName,
      salutation: patientInfo.demographics.salutation,
      suffix: patientInfo.demographics.suffix,
    }),
    nextTargetedVisit: NextTargetedVisit({
      date: patientInfo.scheduling.nextTargetedVisitDate,
    }),
    primaryCarePhysician: Physician({
      id: patientInfo.primaryCarePhysician.id,
      name: patientInfo.primaryCarePhysician.name,
      phone: patientInfo.primaryCarePhysician.phone,
      fax: patientInfo.primaryCarePhysician.faxNumber,
    }),
    referringEntity: ReferringEntity({
      id: patientInfo.referringEntity.id,
      name: patientInfo.referringEntity.name,
    }),
    referringPhysician: Physician({
      id: patientInfo.referringPhysician.id,
      name: patientInfo.referringPhysician.name,
      phone: patientInfo.referringPhysician.phone,
    }),
    afsCode: patientInfo.information.afsCode,
    afsLabel: patientInfo.information.afsLabel,
    carePreferences: patientInfo.acpInfo.carePreferences,
    consentForService: patientInfo.aspire.consentForService,
    consentForServiceDate: patientInfo.aspire.consentForServiceDate,
    cqrHotspotter: patientInfo.aspire.cqrHotspotter,
    dateOfBirth: patientInfo.demographics.dateOfBirth,
    dateOfDeath: patientInfo.discharge.dateOfDeath,
    drugAllergiesDescription: patientInfo.clinical.drugAllergiesDescription,
    email: patientInfo.demographics.email,
    empiKey: patientInfo.empiKey,
    fluShotCompletedOn: patientInfo.fluShot.completedOn,
    fluShotCounseling: patientInfo.fluShot.fluShotCounseling,
    fluShotReceived: patientInfo.fluShot.fluShotReceived,
    gender: patientInfo.demographics.gender,
    genderIdentity: patientInfo.demographics.genderIdentity,
    genderIdentityOther: patientInfo.demographics.genderIdentityOther,
    goalsOfCare: patientInfo.acpInfo.goalsOfCare,
    hasDrugAllergies: patientInfo.clinical.hasDrugAllergies,
    herbalSupplements: patientInfo.clinical.herbalSupplements,
    hospiceEligible: patientInfo.acpInfo.hospiceEligible,
    hotspotterDetails: patientInfo.aspire.hotspotterDetails,
    hotspotterPlan: patientInfo.aspire.hotspotterPlan,
    hotspotterPlanOther: patientInfo.aspire.hotspotterPlanOther,
    internalSubstatus: patientInfo.aspire.internalSubstatus,
    isCaseMgmt: patientInfo.isCaseMgmt,
    languageNotes: patientInfo.demographics.languageNotes,
    certLanguage: patientInfo.demographics.certLanguage,
    certEthnicity: patientInfo.demographics.certEthnicity,
    ethnicityExtra: patientInfo.demographics.ethnicityExtra,
    lastFluShotCounselingDate: patientInfo.fluShot.lastFluShotCounselingDate,
    lastFluShotDate: patientInfo.fluShot.lastFluShotDate,
    maritalStatus: patientInfo.demographics.maritalStatus,
    medicationConsent: patientInfo.clinical.medicationConsent,
    medicationConsentDate: patientInfo.clinical.medicationConsentDate,
    medicationNotes: patientInfo.clinical.medicationNotes,
    nextCallDate: patientInfo.scheduling.nextCallDate,
    noFluShotReason: patientInfo.fluShot.noFluShotReason,
    noFluShotReasonOther: patientInfo.fluShot.noFluShotReasonOther,
    nickname: patientInfo.demographics.nickname,
    preferredPronoun: patientInfo.demographics.preferredPronoun,
    primaryCaregiver: patientInfo.general.primaryCaregiver,
    primaryCaregiverOther: patientInfo.general.primaryCaregiverOther,
    primaryChannel: patientInfo.aspire.primaryChannel,
    primaryDiagnosis: patientInfo.clinical.primaryDiagnosis,
    primaryDiagnosisOther: patientInfo.clinical.primaryDiagnosisOther,
    programEnrolled: patientInfo.aspire.programEnrolled,
    providerPrognosis: patientInfo.acpInfo.providerPrognosis,
    race: patientInfo.demographics.race,
    referralFacilitator: patientInfo.referral.referralFacilitator,
    mailingDate: patientInfo.referral.mailingDate,
    mailingType: patientInfo.referral.mailingType,
    receiptOfReferralDate: patientInfo.referral.receiptOfReferralDate,
    referralSourceType: patientInfo.referral.referralSourceType,
    riskStratification: patientInfo.aspire.riskStratification,
    secondaryDiagnosis: patientInfo.clinical.secondaryDiagnosis,
    secondaryDiagnosisOther: patientInfo.clinical.secondaryDiagnosisOther,
    sex: patientInfo.demographics.sex,
    sexualOrientation: patientInfo.demographics.sexualOrientation,
    sexualOrientationOther: patientInfo.demographics.sexualOrientationOther,
    socialSecurityNumber: patientInfo.demographics.socialSecurityNumber,
    status: patientInfo.aspire.status,
    substatus: patientInfo.aspire.substatus,
    tertiaryDiagnosis: patientInfo.clinical.tertiaryDiagnosis,
    tertiaryDiagnosisOther: patientInfo.clinical.tertiaryDiagnosisOther,
    textingApproved: patientInfo.virtualCarePlanning.textingApproved,
    title: patientInfo.demographics.title,
    urgentReferral: patientInfo.referral.urgentReferral,
    videoAptInterest: patientInfo.virtualCarePlanning.videoAptInterest,
    virtualCapable: patientInfo.virtualCarePlanning.virtualCapable,
    visitInstructions: patientInfo.general.visitInstructions,
    writtenLanguageCode: patientInfo.information.writtenLanguageCode,
    writtenLanguageLabel: patientInfo.information.writtenLanguageLabel,
  })

export const getPatientInfoFromValues = (values, patientInfo) => {
  const {
    acpInfo,
    app,
    aspire,
    caseManager,
    clinical,
    demographics,
    discharge,
    general,
    information,
    market,
    primaryCarePhysician,
    referral,
    referringEntity,
    referringPhysician,
    scheduling,
    virtualCarePlanning,
  } = patientInfo

  // NOTE (Adam): need null fallback for merging purposes
  const newAcpInfo = acpInfo.merge({
    carePreferences: values.get('carePreferences', null),
    goalsOfCare: values.get('goalsOfCare', null),
    hospiceEligible: values.get('hospiceEligible', null),
    providerPrognosis: values.get('providerPrognosis', null),
  })

  const newApp = app.merge({
    id: values.getIn(['assignedApp', 'id'], null),
    name: values.getIn(['assignedApp', 'name'], null),
  })

  const newAspire = aspire
    .merge({
      consentForService: values.get('consentForService', null),
      consentForServiceDate: values.get('consentForServiceDate', null),
      cqrHotspotter: values.get('cqrHotspotter', null),
      hotspotterDetails: values.get('hotspotterDetails', null),
      hotspotterPlanOther: values.get('hotspotterPlanOther', null),
      internalSubstatus: values.get('internalSubstatus', null),
      primaryChannel: values.get('primaryChannel', null),
      programEnrolled: values.get('programEnrolled', null),
      status: values.get('status', null),
      substatus: values.get('substatus', null),
      riskStratification: values.get('riskStratification'),
    })
    // Setting separately because Immutable merge converts arrays into Lists
    .set('hotspotterPlan', values.get('hotspotterPlan', null))

  const newCaseManager = caseManager.merge({
    id: values.getIn(['caseManager', 'id'], null),
    name: values.getIn(['caseManager', 'name'], null),
  })

  const newClinical = clinical.merge({
    drugAllergiesDescription: values.get('drugAllergiesDescription', null),
    hasDrugAllergies: values.get('hasDrugAllergies', null),
    herbalSupplements: values.get('herbalSupplements', null),
    medicationConsent: values.get('medicationConsent', null),
    medicationConsentDate: values.get('medicationConsentDate', null),
    medicationNotes: values.get('medicationNotes', null),
    primaryDiagnosis: values.get('primaryDiagnosis', null),
    primaryDiagnosisOther: values.get('primaryDiagnosisOther', null),
    secondaryDiagnosis: values.get('secondaryDiagnosis', null),
    secondaryDiagnosisOther: values.get('secondaryDiagnosisOther', null),
    tertiaryDiagnosis: values.get('tertiaryDiagnosis', null),
    tertiaryDiagnosisOther: values.get('tertiaryDiagnosisOther', null),
  })

  const newDemographics = demographics.merge({
    dateOfBirth: values.get('dateOfBirth', null),
    firstName: values.getIn(['name', 'firstName'], null),
    lastName: values.getIn(['name', 'lastName'], null),
    maritalStatus: values.get('maritalStatus', null),
    middleName: values.getIn(['name', 'middleName'], null),
    nickname: values.get('nickname', null),
    preferredPronoun: values.get('preferredPronoun', null),
    salutation: values.getIn(['name', 'salutation'], null),
    socialSecurityNumber: values.get('socialSecurityNumber', null),
    suffix: values.getIn(['name', 'suffix'], null),
    title: values.get('title', null),
    sex: values.get('sex', null),
    sexualOrientation: values.get('sexualOrientation', null),
    sexualOrientationOther: values.get('sexualOrientationOther', null),
    genderIdentity: values.get('genderIdentity', null),
    genderIdentityOther: values.get('genderIdentityOther', null),
    certLanguage: values.get('certLanguage', null),
    languageNotes: values.get('languageNotes', null),
    certEthnicity: values.get('certEthnicity', null),
    ethnicityExtra: values.get('ethnicityExtra', null),
    race: values.get('race', null),
  })

  const newDischarge = discharge.merge({
    dateOfDeath: values.get('dateOfDeath', null),
  })

  const newGeneral = general.merge({
    primaryCaregiver: values.get('primaryCaregiver', null),
    primaryCaregiverOther: values.get('primaryCaregiverOther', null),
    visitInstructions: values.get('visitInstructions', null),
  })

  const newInformation = information.merge({
    afsCode: values.get('afsCode', null),
    afsLabel: values.get('afsLabel', null),
    writtenLanguageCode: values.get('writtenLanguageCode', null),
    writtenLanguageLabel: values.get('writtenLanguageLabel', null),
  })

  const newMarket = market.merge({
    id: values.getIn(['assignedMarket', 'id'], null),
    name: values.getIn(['assignedMarket', 'name'], null),
    neighborhood: values.getIn(['assignedMarket', 'neighborhood'], null),
    region: values.getIn(['assignedMarket', 'region'], null),
  })

  const newPrimaryCarePhysician = primaryCarePhysician.merge({
    id: values.getIn(['primaryCarePhysician', 'id'], null),
    name: values.getIn(['primaryCarePhysician', 'name'], null),
    phone: values.getIn(['primaryCarePhysician', 'phone'], null),
    faxNumber: values.getIn(['primaryCarePhysician', 'fax'], null),
  })

  const newReferral = referral.merge({
    referralFacilitator: values.get('referralFacilitator', null),
    mailingDate: values.get('mailingDate', null),
    mailingType: values.get('mailingType', null),
    receiptOfReferralDate: values.get('receiptOfReferralDate', null),
    referralSourceType: values.get('referralSourceType', null),
    urgentReferral: values.get('urgentReferral', null),
  })

  const newReferringEntity = referringEntity.merge({
    id: values.getIn(['referringEntity', 'id'], null),
    name: values.getIn(['referringEntity', 'name'], null),
  })

  const newReferringPhysician = referringPhysician.merge({
    id: values.getIn(['referringPhysician', 'id'], null),
    name: values.getIn(['referringPhysician', 'name'], null),
    phone: values.getIn(['referringPhysician', 'phone'], null),
  })

  const newScheduling = scheduling.merge({
    nextCallDate: values.get('nextCallDate'),
    appNtv: values.getIn(['appNtv'], null),
  })

  const newVirtualCarePlanning = virtualCarePlanning.merge({
    virtualCapable: values.get('virtualCapable'),
    textingApproved: values.get('textingApproved'),
    videoAptInterest: values.get('videoAptInterest'),
  })

  return patientInfo.merge({
    acpInfo: newAcpInfo,
    app: newApp,
    appId: newApp.id,
    aspire: newAspire,
    caseManager: newCaseManager,
    clinical: newClinical,
    demographics: newDemographics,
    discharge: newDischarge,
    general: newGeneral,
    information: newInformation,
    market: newMarket,
    marketId: newMarket.id,
    primaryCarePhysician: newPrimaryCarePhysician,
    primaryCarePhysicianId: newPrimaryCarePhysician.id,
    referral: newReferral,
    referringEntity: newReferringEntity,
    referringEntityId: newReferringEntity.id,
    referringPhysician: newReferringPhysician,
    referringPhysicianId: newReferringPhysician.id,
    scheduling: newScheduling,
    virtualCarePlanning: newVirtualCarePlanning,
  })
}

export const getValuesFromContactInfo = ({ demographics }) =>
  Map({
    currentLocation: demographics.currentLocation,
    locationType: demographics.locationType,
    address: Location({
      street: demographics.street,
      apartment: demographics.apartment,
      city: demographics.city,
      state: demographics.state,
      stateCode: demographics.stateCode,
      postalCode: demographics.postalCode,
      latitude: demographics.latitude,
      longitude: demographics.longitude,
      exact: demographics.locationExact,
    }),
    otherAddress: Location({
      street: demographics.otherStreet,
      apartment: demographics.otherApartment,
      city: demographics.otherCity,
      state: demographics.otherState,
      stateCode: demographics.otherStateCode,
      postalCode: demographics.otherPostalCode,
      latitude: demographics.otherLatitude,
      longitude: demographics.otherLongitude,
    }),
  })

export const getContactInfoFromValues = (values, patientInfo) => {
  const { demographics } = patientInfo

  const newDemographics = demographics.merge({
    currentLocation: values.get('currentLocation', null),
    locationType: values.get('locationType', null),
    street: values.getIn(['address', 'street'], null),
    apartment: values.getIn(['address', 'apartment'], null),
    city: values.getIn(['address', 'city'], null),
    state: values.getIn(['address', 'state'], null),
    stateCode: values.getIn(['address', 'stateCode'], null),
    postalCode: values.getIn(['address', 'postalCode'], null),
    latitude: values.getIn(['address', 'latitude'], null),
    longitude: values.getIn(['address', 'longitude'], null),
    locationExact: values.getIn(['address', 'exact'], false),
    otherStreet: values.getIn(['otherAddress', 'street'], null),
    otherApartment: values.getIn(['otherAddress', 'apartment'], null),
    otherCity: values.getIn(['otherAddress', 'city'], null),
    otherState: values.getIn(['otherAddress', 'state'], null),
    otherStateCode: values.getIn(['otherAddress', 'stateCode'], null),
    otherPostalCode: values.getIn(['otherAddress', 'postalCode'], null),
    otherLatitude: values.getIn(['otherAddress', 'latitude'], null),
    otherLongitude: values.getIn(['otherAddress', 'longitude'], null),
  })

  return patientInfo.merge({
    demographics: newDemographics,
  })
}

export const transformRiskLevel = (
  isCaremore,
  riskStratification,
  riskLevel
) => {
  const GREEN_LEVEL_RISK = '3'
  if (isCaremore) {
    return riskLevel?.toString()
  } else {
    return riskStratification === 'Low' || riskStratification === null
      ? riskLevel?.toString() || GREEN_LEVEL_RISK
      : riskStratification || GREEN_LEVEL_RISK
  }
}
