import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field } from 'redux-form'
import { renderFieldValueOptions } from '~/components/fields'
import { SelectField, TextField } from '~/components/formFields'
import { Section } from '~/components/text'
import { fetchDistinctFieldValues, getFieldValues } from '~/data/fieldValues'
import { useMountEffect } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing, breakpoints }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    columnGap: spacing(2),
    rowGap: `${spacing(2)}px`,

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  spanTwo: {
    gridColumnStart: 'span 2',

    [breakpoints.down('sm')]: {
      gridColumnStart: 'auto',
    },
  },
  spanThree: {
    gridColumnStart: 'span 3',

    [breakpoints.down('sm')]: {
      gridColumnStart: 'auto',
    },
  },
})

const PhysicianInfo = ({
  classes,
  salutations,
  specialties,
  fetchDistinctFieldValues,
}) => {
  useMountEffect(() => {
    fetchDistinctFieldValues(['person_salutation', 'physician_specialty'])
  })

  return (
    <Section title="Physician Information">
      <div className={classes.container}>
        {/* Salutation */}
        <Field
          component={SelectField}
          name="salutation"
          label="Salutation"
          native
        >
          <option />
          {renderFieldValueOptions(salutations)}
        </Field>

        {/* First Name */}
        <Field component={TextField} name="firstName" label="First Name" />

        {/* Middle Name */}
        <Field component={TextField} name="middleName" label="Middle Name" />

        {/* Last Name */}
        <Field component={TextField} name="lastName" label="Last Name" />

        {/* Suffix */}
        <Field component={TextField} name="suffix" label="Suffix" />

        {/* Title */}
        <Field
          className={classes.spanThree}
          component={TextField}
          name="title"
          label="Title"
        />

        {/* Specialty */}
        <Field
          className={classes.spanTwo}
          component={SelectField}
          name="specialty"
          label="Specialty"
          native
        >
          <option />
          {renderFieldValueOptions(specialties)}
        </Field>
      </div>
    </Section>
  )
}

PhysicianInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  salutations: PropTypes.map,
  specialties: PropTypes.map,
  fetchDistinctFieldValues: PropTypes.func.isRequired,
}

export default compose(
  connect(
    state => ({
      salutations: getFieldValues('person_salutation')(state),
      specialties: getFieldValues('physician_specialty')(state),
    }),
    { fetchDistinctFieldValues }
  ),
  withStyles(styles)
)(PhysicianInfo)
