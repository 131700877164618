import { List, Map, Record, fromJS } from 'immutable'
import { HBPC_HYBRID, HOME_BASED_PALLIATIVE_CARE } from '~/utils/programInfo'
export const Referral = Record({
  caseId: null,
  status: null,
  substatus: null,
  info: {
    status: null,
    substatus: null,
    assignedAPPId: null,
    assignedAPPName: null,
    assignedCareTeamId: null,
    assignedCareTeamLabel: null,
    sourceType: null,
    pcpId: null,
    pcpName: null,
    referringPhysicianId: null,
    referringPhysicianName: null,
    referringEntityId: null,
    referringEntityName: null,
    referralDate: null,
    receiptOfReferralDate: null,
    program: null,
    caseManagerId: null,
    caseManagerName: null,
    urgent: null,
    referralListBatchId: null,
    healthplanId: null,
    healthplanName: null,
    marketId: null,
    marketName: null,
    engagementSpecialist: null,
    globalMemberId: null,
    primaryChannelName: null,
    primaryChannelId: null,
    secondaryChannelName: null,
    secondaryChannelId: null,
    primaryChannelEnd: null,
    primaryChannelStart: null,
    secondaryChannelStart: null,
    secondaryChannelEnd: null,
    primaryDiagnosis: null,
    secondaryDiagnosis: null,
    otherDiagnosis: null,
    diagnosisCodes: null,
    referralNote: null,
    visitInstructions: null,
    patientSfid: null,
    patientPgid: null,
    facilitator: null,
  },
  person: {
    personId: null,
    salutation: null,
    firstName: null,
    lastName: null,
    middleName: null,
    sex: null,
    dob: null,
    street1: null,
    city: null,
    state: null,
    postalCode: null,
  },
  insuranceCoverages: {
    primary: {
      accountNumber: null,
      groupNumber: null,
      groupName: null,
      notes: null,
      effectiveStart: null,
      effectiveEnd: null,
      subscriber: null,
      dual: null,
      secondaryId: null,
      healthplanCrosswalkId: null,
      healthplanId: null,
      healthplanName: null,
      mbi: null,
      maPcpi: null,
      program: null,
      custom: null,
    },
    secondary: {
      accountNumber: null,
      groupNumber: null,
      groupName: null,
      notes: null,
      effectiveStart: null,
      effectiveEnd: null,
      subscriber: null,
      dual: null,
      secondaryId: null,
      healthplanId: null,
      healthplanName: null,
      program: null,
      custom: null,
    },
  },
  contacts: null,
})
export const getValuesFromReferralCase = (ref = Referral()) => {
  const info = (ref as any).info
  const person = (ref as any).person
  const insuranceCoverages = (ref as any).insuranceCoverages || {
    primary: {},
    secondary: {},
  }
  const hybridHealthplans = ['Humana', 'Transcend', 'SCAN']
  const defaultToHybrid = hybridHealthplans.includes(
    insuranceCoverages.primary.healthplanName
  )

  const contacts = (ref as any).contacts || [
    {
      preferred: true,
      type: 'Other',
    },
  ]
  if (!(ref as any).info || !(ref as any).person) {
    return Map()
  }
  return Map({
    patient: Map({
      firstName: person.firstName,
      lastName: person.lastName,
      middleName: person.middleName,
      dob: person.dob,
      sex: person.sex,
      salutation: person.salutation,
    }),
    address: Map({
      street1: person.street1,
      street2: person.street2,
      city: person.city,
      state: person.state,
      postalCode: person.postalCode,
    }),
    aspire: Map({
      assignedAPP: Map({
        name: info.assignedAPPName,
        id: info.assignedAPPId,
      }),
      assignedCareTeam: Map({
        label: info.assignedCareTeamLabel,
        id: info.assignedCareTeamId,
      }),
      engagementSpecialist: info.engagementSpecialist,
      primaryChannel: Map({
        name: info.primaryChannelName,
        id: info.primaryChannelId,
        start: info.primaryChannelStart,
        end: info.primaryChannelEnd,
      }),
      secondaryChannel: Map({
        name: info.secondaryChannelName,
        id: info.secondaryChannelId,
        start: info.secondaryChannelStart,
        end: info.secondaryChannelEnd,
      }),
      visitInstructions: info.visitInstructions,
      program: defaultToHybrid
        ? info.program || HBPC_HYBRID
        : info.program || HOME_BASED_PALLIATIVE_CARE,
      patientSfid: info.patientSfid,
      patientPgid: info.patientPgid,
    }),
    healthplan: Map({
      id: info.healthplanId,
      primaryInsurance: Map({
        accountNumber: insuranceCoverages.primary.accountNumber,
        secondaryId: insuranceCoverages.primary.secondaryId,
        groupNumber: insuranceCoverages.primary.groupNumber,
        groupName: insuranceCoverages.primary.groupName,
        notes: insuranceCoverages.primary.notes,
        effectiveStart: insuranceCoverages.primary.effectiveStart,
        effectiveEnd: insuranceCoverages.primary.effectiveEnd,
        subscriber: insuranceCoverages.primary.subscriber,
        healthplanCrosswalkId: info.primaryChannelId,
        healthplanId: insuranceCoverages.primary.healthplanId,
        healthplanName: insuranceCoverages.primary.healthplanName,
        mbi: insuranceCoverages.primary.mbi,
        maPcpi: insuranceCoverages.primary.maPcpi,
        channelName: info.primaryChannelName,
        dual: insuranceCoverages.primary.dual,
        program: insuranceCoverages.primary.program,
        custom: fromJS(insuranceCoverages.primary.custom || []).reduce(
          (value: any, next: any) =>
            value.set(next.get('name'), next.get('value')),
          Map()
        ),
      }),
      secondaryInsurance: Map({
        accountNumber: insuranceCoverages.secondary.accountNumber,
        secondaryId: insuranceCoverages.secondary.secondaryId,
        groupNumber: insuranceCoverages.secondary.groupNumber,
        groupName: insuranceCoverages.secondary.groupName,
        notes: insuranceCoverages.secondary.notes,
        effectiveStart: insuranceCoverages.secondary.effectiveStart,
        effectiveEnd: insuranceCoverages.secondary.effectiveEnd,
        subscriber: insuranceCoverages.secondary.subscriber,
        healthplanId: insuranceCoverages.secondary.healthplanId,
        healthplanName: insuranceCoverages.secondary.healthplanName,
        channelName: info.secondaryChannelName,
        dual: insuranceCoverages.secondary.dual,
        program: insuranceCoverages.secondary.program,
        custom: fromJS(insuranceCoverages.secondary.custom || []).reduce(
          (value: any, next: any) =>
            value.set(next.get('name'), next.get('value')),
          Map()
        ),
      }),
      zip: person.zip,
    }),
    diagnosis: Map({
      primary: info.primaryDiagnosis,
      secondary: info.secondaryDiagnosis,
      other: info.otherDiagnosis,
      codes: info.diagnosisCodes,
    }),
    referralInfo: Map({
      pcp: Map({
        name: info.pcpName,
        id: info.pcpId,
      }),
      referringPhysician: Map({
        name: info.referringPhysicianName,
        id: info.referringPhysicianId,
      }),
      referringEntity: Map({
        name: info.referringEntityName,
        id: info.referringEntityId,
      }),
      caseManager: Map({
        name: info.caseManagerName,
        id: info.caseManagerId,
      }),
      facilitator: info.facilitator,
      urgent: info.urgent,
      referralNote: info.referralNote,
      referralDate: info.referralDate,
      receiptOfReferralDate: info.receiptOfReferralDate,
      referralListBatchId: info.referralListBatchId,
      sourceType: info.sourceType,
      globalMemberId: info.globalMemberId,
    }),
    contacts: fromJS(contacts),
  })
}
export const getReferralCaseFromValues = (values: any, referral = Referral()) =>
  referral.merge({
    info: {
      assignedAPPId: values.getIn(['aspire', 'assignedAPP', 'id']),
      assignedCareTeamId: values.getIn(['aspire', 'assignedCareTeam', 'id']),
      sourceType: values.getIn(['referralInfo', 'sourceType']),
      pcpId: values.getIn(['referralInfo', 'pcp', 'id']),
      referringPhysicianId: values.getIn([
        'referralInfo',
        'referringPhysician',
        'id',
      ]),
      referringEntityId: values.getIn([
        'referralInfo',
        'referringEntity',
        'id',
      ]),
      primaryChannelId: values.getIn(['aspire', 'primaryChannel', 'id']),
      secondaryChannelId: values.getIn(['aspire', 'secondaryChannel', 'id']),
      primaryChannelStart: values.getIn(['aspire', 'primaryChannel', 'start']),
      primaryChannelEnd: values.getIn(['aspire', 'primaryChannel', 'end']),
      secondaryChannelStart: values.getIn([
        'aspire',
        'secondaryChannel',
        'start',
      ]),
      secondaryChannelEnd: values.getIn(['aspire', 'secondaryChannel', 'end']),
      referralDate: values.getIn(['referralInfo', 'referralDate']),
      receiptOfReferralDate: values.getIn([
        'referralInfo',
        'receiptOfReferralDate',
      ]),
      facilitator: values.getIn(['referralInfo', 'facilitator']),
      program: values.getIn(['aspire', 'program']),
      caseManagerId: values.getIn(['referralInfo', 'caseManager', 'id']),
      urgent: values.getIn(['referralInfo', 'urgent']),
      referralListBatchId: values.getIn([
        'referralInfo',
        'referralListBatchId',
      ]),
      healthplanId: (
        values.getIn(['healthplan', 'primaryInsurance', 'healthplanId']) || 0
      ).toString(),
      market: values.getIn(['section', 'market']),
      engagementSpecialist: values.getIn(['aspire', 'engagementSpecialist']),
      globalMemberId: values.getIn(['referralInfo', 'globalMemberId']),
      primaryDiagnosis: values.getIn(['diagnosis', 'primary']),
      secondaryDiagnosis: values.getIn(['diagnosis', 'secondary']),
      otherDiagnosis: values.getIn(['diagnosis', 'other']),
      diagnosisCodes: values.getIn(['diagnosis', 'codes']),
      referralNote: values.getIn(['referralInfo', 'referralNote']),
      visitInstructions: values.getIn(['aspire', 'visitInstructions']),
      patientSfid: values.getIn(['aspire', 'patientSfid']),
      patientPgid: values.getIn(['aspire', 'patientPgid']),
    },
    person: {
      personId: values.getIn(['section', 'personId']),
      salutation: values.getIn(['patient', 'salutation']),
      firstName: values.getIn(['patient', 'firstName']),
      lastName: values.getIn(['patient', 'lastName']),
      middleName: values.getIn(['patient', 'middleName']),
      sex: values.getIn(['patient', 'sex']),
      dob: values.getIn(['patient', 'dob']),
      street1: values.getIn(['address', 'street1']),
      street2: values.getIn(['address', 'street2']),
      city: values.getIn(['address', 'city']),
      state: values.getIn(['address', 'state']),
      postalCode: values.getIn(['address', 'postalCode']),
    },
    insuranceCoverages: {
      primary: {
        accountNumber: values.getIn([
          'healthplan',
          'primaryInsurance',
          'accountNumber',
        ]),
        groupNumber: values.getIn([
          'healthplan',
          'primaryInsurance',
          'groupNumber',
        ]),
        groupName: values.getIn([
          'healthplan',
          'primaryInsurance',
          'groupName',
        ]),
        notes: values.getIn(['healthplan', 'primaryInsurance', 'notes']),
        secondaryId: values.getIn([
          'healthplan',
          'primaryInsurance',
          'secondaryId',
        ]),
        effectiveStart: values.getIn([
          'healthplan',
          'primaryInsurance',
          'effectiveStart',
        ]),
        effectiveEnd: values.getIn([
          'healthplan',
          'primaryInsurance',
          'effectiveEnd',
        ]),
        healthplanId: values.getIn([
          'healthplan',
          'primaryInsurance',
          'healthplanId',
        ]),
        mbi: values.getIn(['healthplan', 'primaryInsurance', 'mbi']),
        maPcpi: values.getIn(['healthplan', 'primaryInsurance', 'maPcpi']),
        healthplanCrosswalkId: values.getIn(['aspire', 'primaryChannel', 'id']),
        subscriber: values.getIn([
          'healthplan',
          'primaryInsurance',
          'subscriber',
        ]),
        dual: values.getIn(['healthplan', 'primaryInsurance', 'dual'])
          ? true
          : false,
        custom: toArrayObject(
          values.getIn(['healthplan', 'primaryInsurance', 'custom'], Map())
        ),
      },
      secondary: {
        accountNumber: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'accountNumber',
        ]),
        groupNumber: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'groupNumber',
        ]),
        groupName: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'groupName',
        ]),
        notes: values.getIn(['healthplan', 'secondaryInsurance', 'notes']),
        effectiveStart: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'effectiveStart',
        ]),
        effectiveEnd: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'effectiveEnd',
        ]),
        subscriber: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'subscriber',
        ]),
        healthplanId: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'healthplanId',
        ]),
        healthplanCrosswalkId: values.getIn([
          'aspire',
          'secondaryChannel',
          'id',
        ]),
        secondaryId: values.getIn([
          'healthplan',
          'secondaryInsurance',
          'secondaryId',
        ]),
        dual: values.getIn(['healthplan', 'secondaryInsurance', 'dual'])
          ? true
          : false,
        custom: toArrayObject(
          values.getIn(['healthplan', 'secondaryInsurance', 'custom'], Map())
        ),
      },
    },
    contacts: values.get('contacts', List()).toJS(),
  })
const toArrayObject = (map: any) => {
  const keys = Object.keys(map.toJS())
  return fromJS(
    keys.map(key => {
      return { name: key, value: map.get(key) }
    })
  )
}
