import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { concatMap, filter, map, switchMap } from 'rxjs/operators'
import { fetchDemographicsOptions } from '~/data/demographicsOptions'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { fetchPatientCareTeam } from '~/features/careTeamManagement'
import { patientIdSelected } from '~/features/careTeamManagement/data/currentPatientId'
import { fetchLastLogCommunication } from '~/features/caremoreLogCommunication/data/lastLogCommunication'
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
import { fetchInsuranceCoverages } from '~/features/insuranceManagement'
import { fetchNextTargetedVisitDates } from '~/features/nextTargetedVisitDate'
import { fetchAlerts } from '~/features/patientAlerts'
import { fetchPatient } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'
import { fetchAccessAuthorization } from '../data/accessAuthorization'
import { getPatientId } from '../data/patientId'
import router from '../router'

const operations = [
  patientId => fetchPatient.requested(patientId),
  patientId => fetchInsuranceCoverages.requested(patientId),
  patientId => fetchAlerts.requested(patientId),
  patientId => patientIdSelected(patientId),
  patientId => fetchAccessAuthorization.requested(patientId),
  patientId => fetchEpisodesOfCare.requested({ patientId }),
  patientId => fetchPatientCareTeam.requested(patientId),
  patientId => fetchNextTargetedVisitDates.requested(patientId),
  patientId => fetchLastLogCommunication.requested(patientId),
  () => fetchDemographicsOptions.requested(),
  () =>
    fetchDistinctFieldValues([
      'are_there_any_drug_allergies',
      'aspire_program_enrolled',
      'care_preferences',
      'contact_current_location',
      'contact_location_type',
      'ethnicity',
      'gender',
      'gender_identity',
      'hospice_eligible',
      'hotspotter_plan',
      'language',
      'referral_mailing_type',
      'marital_status',
      'market',
      'patient_diagnosis',
      'patient_internal_substatus',
      'patient_status',
      'person_salutation',
      'physician_specialty',
      'primary_care_giver',
      'preferred_pronoun',
      'provider_prognosis',
      'referral_facilitator',
      'referral_source_type',
      'referred_patient_substatus',
      'reinstatement_reason',
      'risk_stratification',
      'salutation',
      'sex',
      'sexual_orientation',
      'title',
      'texting_approved',
      'us_state',
      'video_apt_interest',
      'virtual_capable',
      'yes_no',
    ]),
]

export default (action$, state$) =>
  action$.pipe(
    filter(entered(router)),
    switchMap(action =>
      of(action).pipe(
        switchTo(state$),
        map(getPatientId),
        concatMap(patientId =>
          of(...operations.map(operation => operation(patientId)))
        )
      )
    )
  )
