/**
 * Uppercases the first letter of each word
 * lowercases rest
 * @param {string} str
 *
 * @example
 * titleCase('each word in title case')
 * // returns 'Each Word In Title Case'
 *
 * @example
 * titleCase('eacH fIrst Letter CAPITAlIZED')
 * // returns 'Each First Letter Capitalized'
 *
 * @example
 * titleCase('1 2 3')
 * // returns '1 2 3'
 *
 * @example
 * titleCase(666)
 * // returns 666
 *
 * @returns {string} Returns a new string with each word capitalized.
 */
export const titleCase = str =>
  typeof str == 'string'
    ? str
        .replace(/\b\w/g, first => first.toUpperCase())
        .replace(/\B\w/g, rest => rest.toLowerCase())
    : str

/**
 * Turn camelCase into character deliminated case
 * ie. 'snake_case' or 'kebab-case' or 'human readable'
 * with all characters lowercased
 * @param {string} str
 * @param {string} delimiter - defaults to '_'
 *
 * @example
 * decamelize('eachWordInCamelCase')
 * // returns "each_word_in_camel_case"
 *
 * @example
 * decamelize('EachWordInPascalCase', ' ')
 * //returns "each word in pascal case"
 *
 * @example
 * decamelize('1 2 3')
 * // returns '1 2 3'
 *
 * @example
 * decamelize(666)
 * // returns 666
 *
 * @returns {string} Returns a new string with each word separated
 * by provided delimiter or '_'
 */
export const decamelize = (str, delimiter = '_') =>
  typeof str === 'string'
    ? str.replace(/([a-zA-Z])(?=[A-Z])/g, `$1${delimiter}`).toLowerCase()
    : str

/**
 * Split string on new lines
 * @param {string} str
 *
 * @example
 * splitOnNewLines('yo')
 * // returns ['yo']
 *
 * @example
 * splitOnNewLines('yo\nhey\r\nhello')
 * // returns ['yo', 'hey', 'hello']
 *
 * @example
 * splitOnNewLines('\r\ntrim me\n')
 * // returns ['trim me']
 *
 * @example
 * splitOnNewLines(null)
 * // returns []
 */
export const splitOnNewLines = str =>
  typeof str == 'string' ? str.trim().split(/\r?\n/) : []

/**
 * Takes a string and replaces all underscores with spaces
 */
export const removeUnderscores = str =>
  typeof str === 'string' ? str.replace(/_/g, ' ') : str

/**
 * Takes a string and capitalizes the first letter of that string only
 */
export const capitalizeFirst = str =>
  typeof str === 'string'
    ? str.replace(/^./, first => first.toUpperCase())
    : str || ''
